import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import toast from "react-hot-toast"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Breadcrumbs from "../components/addon/breadcrumbs"
import SimpleReactValidator from "simple-react-validator"

import { acceptOnlyNumbers } from "../service/helper"

import API from "../api/print-voucher"
import API_USER_VALIDATE from "../api/user"
import APIButton from "../components/addon/button/apiButton"


const PrintVoucher = (props) => {
    const [hhNumber, setHHNumber] = useState('')
    const [serviceType, setserviceType] = useState('')
    const [serviceData, setserviceData] = useState('')
    const [loading, setLoading] = useState(false) 
    const [noData, setnoData] = useState(false) 
    const [hhValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className="field_error" > {message} </p>
    }
    )))

    const [breadcrumbs ] = useState([
        { title: "Home", link: "/",},
        { title: "Print Voucher", },
    ]) 

    const refreshPage = ()=>{
        window.location.reload();
    }

    const getEventsDetailsById = (HHNo, type) => {
        API.getVoucherData(HHNo, type)
            .then(res => {
                if(res.length > 0){
                    setserviceData( current => [...current, ...res] )
                }else{
                    setnoData(true)
                    setTimeout( () => {
                        setnoData(false)
                    },5000)
                }
                window.scroll({ top: 0, behavior: 'smooth' });
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }
    
    const showForm = (e) => {
        let hhServicesSec = document.getElementById('hhServicesSec');
        let enterHHNoSec = document.getElementById('enterHHNoSec');
        hhServicesSec.style.display = 'none';
        enterHHNoSec.style.display = 'block';
        window.scroll({ top: 0, behavior: 'smooth' });
        setserviceType(e.target.dataset.type)
    }
    
    const handleHHSubmit = (e) => {
        e.preventDefault();
        const hhResult = hhValidator.current.allValid()
        if (!hhResult) {
            hhValidator.current.showMessages()
        }
        if (hhResult && hhNumber?.length) {
            validateUserHHNumber()
        }
    }
    const validateUserHHNumber = () => {
        setLoading(true)
        API_USER_VALIDATE.validateHHNumber(hhNumber)
            .then(res => {
                if (res) {
                    getEventsDetailsById(parseInt(hhNumber), serviceType);
                    console.log('serviceType=====>>>>>>>>>>',serviceType)
                    if(serviceType === 'DEP'){
                        getEventsDetailsById(parseInt(hhNumber), 'DCH');
                        getEventsDetailsById(parseInt(hhNumber), 'OPD');
                    }
                } else {
                    toast.error("Invalid HH Number")
                    //setLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
                toast.error("Invalid HH Number")
            })
    }
    
    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        '/assets/css/services.css',
                        '/assets/css/about-through-the-ages.css',
                        '/assets/css/accessibility.css',
                        '/assets/css/common.css',
                    ]
                }}
                tags={[]}
            />
            {breadcrumbs &&
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                {
                                    breadcrumbs.map( (item, i) => {
                                        return item?.link ? <li key={i}><a href={item?.link}>{item?.title}</a></li> : <li key={i}><span>{item?.title}</span></li>
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <main className="innerpage">
                <section id="hhServicesSec" className="services_section common_services three_cols section-py section-bg pb-0">
                    <div className="container">
                        <h2>Patients Services</h2>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="service_card">
                                    <figure>
                                        <img src="/assets/images/print-voucher/pay-ipd-bills.svg" alt="" />
                                    </figure>
                                    <h3>OPD Services / Doctor Consultation</h3>
                                    <div className="btn_wrap">
                                        <a href="javasctipt:;" onClick={showForm} data-type="DEP" className="btn btn-primary-solid">Proceed</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service_card">
                                    <figure>
                                        <img src="/assets/images/print-voucher/opd-deposite.svg" alt="" />
                                    </figure>
                                    <h3>IPD bill payment for admitted patient</h3>
                                    <div className="btn_wrap">
                                        <a href="javasctipt:;" onClick={showForm} data-type="ADMIN" className="btn btn-primary-solid">Proceed</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service_card">
                                    <figure>
                                        <img src="/assets/images/print-voucher/reservation-deposite.svg" alt="" />
                                    </figure>
                                    <h3>Reservation deposit</h3>
                                    <div className="btn_wrap">
                                        <a href="javasctipt:;" onClick={showForm} data-type="RSV" className="btn btn-primary-solid">Proceed</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="service_card">
                                    <figure>
                                        <img src="/assets/images/print-voucher/pay-ipd-bills.svg" alt="" />
                                    </figure>
                                    <h3>LAB Test</h3>
                                    <div className="btn_wrap">
                                        <a href="https://onlineservices.hindujahospital.com/for-patients/online-payment-kiosk/login.aspx" target="_blank" className="btn btn-primary-solid">Proceed</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {serviceData?.length === 0 ? 
                <section id="enterHHNoSec" className="section_bg">
                    <div className="container">
                        <h2 className="section-heading">Enter your details</h2>
                        <div className="row no-gutters">
                            <div className="col-lg-12 col-md-12">
                                <div className="white_rounded_box">
                                    <div className="form_wrap">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group -animated">
                                                    <label>Enter HH No. <span className="mandatory">*</span></label>
                                                    {/* <input id="hhInput" class="form-control" type="text" /> */}
                                                    <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                    {hhValidator.current.message('HhNumber', hhNumber, 'required|min:3|max:10')}
                                                    {noData === true ? <p className="field_error">Data not Found</p> : null} 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_wrap">
                                            <APIButton title={`Submit`}
                                                loading={loading} 
                                                disabled={hhNumber?.length >= 3 ? false : true}
                                                onBtnClick={(e) => handleHHSubmit(e)}
                                                className={`btn btn-primary`}
                                            />
                                            <button onClick={refreshPage} class="btn btn-primary ml-3">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : null}

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 pb-4">
                                {
                                    serviceType === 'DEP' && serviceData.length > 0 ? 
                                    <>
                                        <div>
                                            <h3 className="mb-3">Appointment Details</h3>
                                            <a onClick={refreshPage} className="backButton" href="javascript:;">&#60; Back</a>
                                            <div className="table-price-wrap table-price-wrap-orange pb-3">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr style={{color: "#fff", background:"#ff9836"}}>
                                                            <td><strong>ORE</strong></td>
                                                            <td><strong>Appointment No</strong></td>
                                                            <td><strong>Date & Time</strong></td>
                                                            <td><strong>Consultant Name</strong></td>
                                                            <td><strong>Amount</strong></td>
                                                            <td></td>
                                                        </tr>
                                                        {serviceData && serviceData.length && serviceData.map( ( item, i ) => {
                                                            return(
                                                                <tr key={i}>
                                                                    <td>{item?.data?.voucherno || '-'}</td>
                                                                    <td>{item?.data?.apptno || '-'}</td>
                                                                    <td>{item?.data?.transactionDate || '-'}</td>
                                                                    <td>{item?.data?.consultname || '-'}</td>
                                                                    <td>{item?.data?.amountpaid || '-'}</td>
                                                                    <td align="center"><a href={item?.print_voucher_link || ''} target="_blank" className="btn btn-primary-solid">Print</a></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><i>If you find any difficulty in printing at KIOSK, please connect helpdesk or OPD Officer on duty</i></p>
                                        </div>
                                    </>
                                    : serviceType === 'ADMIN' && serviceData.length > 0 ?
                                    <>
                                        <div>
                                            <p className="mb-1">Dear <strong>{serviceData[0]?.data?.fname}</strong>,</p>
                                            <p>Click on "Print" button to take printout for the voucher</p>
                                            <a onClick={refreshPage} className="backButton" href="javascript:;">&#60; Back</a>
                                            <div className="table-price-wrap table-price-wrap-orange pb-3">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr style={{color: "#fff", background:"#ff9836"}}>
                                                            <th><strong>Admission No.</strong></th>
                                                            <th><strong>Order No</strong></th>
                                                            <th><strong>Amount</strong></th>
                                                            <th><strong>Date</strong></th>
                                                            <th><strong></strong></th>
                                                        </tr>
                                                        {serviceData && serviceData.length && serviceData[0] && serviceData[0].map( ( item, i ) => {
                                                            return(
                                                                <tr key={i}>
                                                                    <td>{item?.data?.admissionNo || '-'}</td>
                                                                    <td>{item?.data?.transactionRefno || '-'}</td>
                                                                    <td>{item?.data?.amountpaid || '-'}</td>
                                                                    <td>{item?.data?.transactionDate || '-'}</td>
                                                                    <td align="center"><a href={item?.print_voucher_link} target="_blank" className="btn btn-primary-solid">Print</a></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    : serviceType === 'RSV' && serviceData.length > 0 ?
                                    <>
                                        <div>
                                            <p className="mb-1">Dear <strong>{serviceData[0]?.data?.fname}</strong>,</p>
                                            <p>Click on "Print" button to take printout for the voucher</p>
                                            <a onClick={refreshPage} className="backButton" href="javascript:;">&#60; Back</a>
                                            <div className="table-price-wrap table-price-wrap-orange pb-3">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr style={{color: "#fff", background:"#ff9836"}}>
                                                            <th><strong>Reservation No.</strong></th>
                                                            <th><strong>Order No</strong></th>
                                                            <th><strong>Amount</strong></th>
                                                            <th><strong>Date</strong></th>
                                                            <th></th>
                                                        </tr>
                                                        {
                                                            serviceData && serviceData.length && serviceData[0] && serviceData[0].map( ( item, i ) => {
                                                                return(
                                                                    <tr key={i}>
                                                                        <td>{item?.data?.reservationNo || '-'}</td>
                                                                        <td>{item?.data?.transactionRefno || '-'}</td>
                                                                        <td>{item?.data?.amountpaid || '-'}</td>
                                                                        <td>{item?.data?.transactionDate || '-'}</td>
                                                                        <td align="center"><a href={item?.print_voucher_link} target="_blank" className="btn btn-primary-solid">Print</a></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default PrintVoucher